@import url('https://fonts.googleapis.com/css?family=Ubuntu');

.bg-color{
    background-image: linear-gradient(rgba(0, 0, 255, 0.363), rgba(255, 255, 0, 0.144));
}
.box-style{
    font-family: 'Times New Roman', Times, serif;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    padding: 15px 5px;
   
}
.box-style2{
    background: #eee;
    box-shadow: 0 8px 8px -4px lightblue;
    padding: 10px 5px;
}
.spacing-style{
    margin-bottom: 30px;
}
.spacing-style div{
    border-radius: 5px;
   
}
.web-headings h3{
   
    background-color: #121212;
    margin:0;
    padding: 12px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #121212;
    text-align: center;
    letter-spacing: 5px;
    text-shadow: -1px 1px 0 #41ba45,
            1px 1px 0 #c63d2b,
           1px -1px 0 #42afac,
          -1px -1px 0 #c6c23f;
}
.box-style-anu{
    color: white;
    background: #eee;
    box-shadow: 0 8px 8px -4px lightblue;
}


.anu-details-style {
    color: white;
    background-color: #e40a77;
    /* bg-primary */
    text-align: center;
    font-family: "Poppins", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    border-radius: 5px;
    font-weight: normal;
    padding: 100px 0;
}