@import url('https://fonts.googleapis.com/css?family=Ubuntu');

.movies-titles{
  background-color: #121212;
  margin: 5px auto;
  padding: 5px 0 ;
  font-family: 'Ubuntu', sans-serif;
  font-size: 35px;
  font-weight: bold;
  color: #CFC547;
  text-align: center;
  letter-spacing: 5px;
  text-shadow: 16px 22px 11px rgba(168,158,32,0.8);
 

}
.movie-box{
  background: #eee;
  box-shadow: 0 8px 8px -4px lightblue;
  padding: 10px 5px;
  margin: 0 ;
}

   .hr-design {
    border: none;
    height: 20px;
    animation-fill-mode: backwards;
    background-image: linear-gradient(to left, rgb(36, 241, 241), rgb(128, 124, 124));
  }

  .animated-border-button{
    margin: 50px 0;
  }
  .animated-border-button {
    border: none;
    color: #ffffff;
    outline: none;
    position: relative;
  }
  
  .animated-border-button:after {
    border: 0 solid transparent;
    transition: all 0.3s;
    content: '';
    height: 0;
    position: absolute;
    width: 24px;
  }
  
.animated-border-button:before {
    border-top: 2px solid #263059;
    right: 0;
    top: -4px;
  }
  
  .animated-border-button:after {
    border-bottom: 2px solid #263059;
    bottom: -4px;
    left: 0;
  }
  
  .animated-border-button:hover:after {
    width: 100%;
  }

  .hoverBoxMovie:hover{
    box-shadow: 0px 0px 30px rgb(183, 135, 223);
    transition: 0.3s;
  }

  .paapa p{
    color:white;
  }