.backToTopStyle{
 position: fixed;
 bottom: 90px;
 right: 37px;
 cursor: pointer;
}

@media only screen and (max-width:576px) {
    .backToTopStyle{
        bottom: 80px;
        right: 9px;
       }

}

@media only screen and (min-width:689px) and (max-width: 991px){
    .backToTopStyle{
        bottom: 90px;
        right: 35px;
       }
}
 /* <Button 
 style={{
 position:'fixed',
 bottom:"100px",
 right:"45px",
 height:"50px",
 width:"50px",
 fontSize:"50px"
 }}
 onClick={scrollUp}
 >
 ^</Button> */


 /* <img 
 style={{
      position:'fixed',
      bottom:"100px",
      right:"45px",
       cursor:"pointer"
    
  }}
  onClick={scrollUp}
  src={bactoToparow} alt="" /> */