.bodys {
    font-family: "Poppins", Arial, sans-serif;
    font-size: 16px;
    line-height: 1.8;
    font-weight: normal;
    background: #fafafa;
    color: gray;
    border-radius: 5px;
 
}
.left-style {
    color: white;
    background-color: #046ef8;
    /* bg-primary */
    text-align: left;
}
.info-wrap .dbox p {
    margin-bottom: 0;
}
.info-wrap .dbox .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
}
.info-wrap .dbox {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 25px;
}
.info-wrap .dbox .icon span {
    font-size: 20px;
    color: #fff;
}
.info-wrap .dbox p a {
    color: #fff;
}
.info-wrap .dbox p span {
    font-weight: 600;
    color: #fff;
}
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.pl-3, .px-3 {
    padding-left: 1rem !important;
}
.info-wrap .dbox .text {
    width: calc(100% - 50px);
}
/* ---------------------------- */
.title-style {
    color: white;
    background-color: transparent;
    background-image: linear-gradient(90deg, #4389A2 5%, #5C258D 90%);
    padding: 40px 0px 40px 0px
}


.naming-style {
    font-family: 'Times New Roman', Times, serif;
    text-align: left;
}


.form-container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;

}

.contact-name {
    text-align: center;
    color: #5d6369;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 600;
    font-size:35px;
}

input,
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 6px;
}

textarea {
    height: 100px;
    resize: none;
}

button {
    background-color: #1b74e4;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: indigo;
    color: orange;
}
/* ------------------- */
.arrows {
	width: 60px;
	height: 72px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: 20px;
}

.arrows path {
	stroke: #2994D1;
	fill: transparent;
	stroke-width: 1px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-1s;
	-webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}
