.header a:hover {
    color: darkslateblue;
    background-color: rgb(39, 22, 100);
}
.text-sizing{
    margin-right: 150px;
}

.hlink {
    color: #2d394b;
    margin-left: 30px;
    font-weight: 400;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic;

}

.hlink:hover {
    color: orange;
}

.fb-tube{
    display: flex;
}



@media only screen and (max-width:688px) {
    .hlink {
        margin-left: 0;
        margin: 0 100px;
    }
    .img-contrl{
        width: 40%;
        margin-left: 165px;
        margin-top: -40px;
        margin-bottom: 10px;
    }
   
    .text-sizing{
        margin-left: 70px;
    }
    .fb-tube{
       margin-top: -50px;
       margin-right: 20px;
    }
}