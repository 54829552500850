@import url('https://fonts.googleapis.com/css?family=Ubuntu');

.gellery-style{
    background-color: black;

}

.bannerStyle{
    color: darkblue;
}
.bannerStyle2{
    color: darkblue;
}

.gallery-headings h2{
    background-color: #121212;
    margin: 0px auto;
    padding: 20px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 40px;
    font-weight: bold;
    color: #121212;
    text-align: center;
    letter-spacing: 5px;
    text-shadow: -1px 1px 0 #41ba45,
            1px 1px 0 #c63d2b,
           1px -1px 0 #42afac,
          -1px -1px 0 #c6c23f;
}