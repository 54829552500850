.love-first {
    background-image: url('../../images/love/cinema11.jpg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px;
    margin-right: 7px;
    margin-left: 16px;
}

.love-second {
    background-image: url('../../images/love/music11.jpg');
    border-radius: 2px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 7px;
    margin-left: 16px;
}

.love-third {
    background-image: url('../../images/love/retro11.jpg');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px;
    margin-left: 16px;
   
}

/* === HEADING STYLE #1 === */
.one {
    margin-bottom: 20px;
    font-family: "Asap", sans-serif;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    font-size: 40px;
    font-weight: 600;
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: repeating-radial-gradient(farthest-corner at 20px 40px, #553c9a, #ee4b2b, #553c9a)
}