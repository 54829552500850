.visaCardFull{
    padding: 50px 0px;
 
}

.visacard-title{
    text-align: center;

}

.visacard-card{
    display: flex;
    flex-wrap: nowrap;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding:15px 5px;

}
.visacard-card div{
    position: relative;
    right: 25px;
}
.visacard-card:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.visacardImg img{
        position: relative;
        left: 30px;
        top: 10px;
        box-sizing: border-box;
       
}
.visacard-card h4{
    color: indigo;
  /* color: dodgerblue; */
   
}
.visacard-card p{
  color: rgb(102, 101, 101);
  font-size: 17px;
   
}
.visacard-card div {
    text-align: start;
}
