@import url(https://fonts.googleapis.com/css?family=Nunito:400,700,300);

.headobj {
	text-align: center;
	text-transform: uppercase;
	color: #4CAF50;

}

.amistext {
	text-indent: 50px;
	text-align: justify;

}

.resume-btn {
	text-transform: uppercase;
	font-family: 'Nunito', sans-serif;
	background-color: #060e33;
	color: #fff;
	border: 2px solid #cb134e;
	display: inline-block;
	padding: 15px;
	padding-right: 50px;
	padding-left: 50px;
	border-radius: 3px;
	cursor: pointer;
	overflow: hidden;
	box-sizing: border-box;
	font-size: 14px;
	transition: all .3s ease;

}

.resume-btn:hover {
	background-color: #cb134e;
	color: #060e33;
}