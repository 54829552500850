.tclors{
    color: whitesmoke;
   
}
  .blocks {
    position: relative;
	background: linear-gradient(0deg, #000, #272727);
	background-color: #000f;
    margin:5px 2px 40px 5px;
}

.blocks:before, .blocks:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: -1;
	animation: steam 20s linear infinite;
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.blocks:after {
	filter: blur(50px);
}



.aboutbanner body{
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
}
.aboutbanner{
    background-image: linear-gradient(indigo, indianred);
    background-color: #cccccc;
    height: 520px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 40px;
}
  .bio-style{
    text-align: left;
    color:whitesmoke;
}
.edu-exp{
      color: rgb(211, 247, 242);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    
  }
.name-title{
    color: indianred;
    text-decoration: wavy;
}
.box-style1{
    box-shadow: 0 0 5px 5px red;
    padding: 30px;
}
.box-style2{
    box-shadow: 0.5rem 0.5rem black, -0.5rem -0.5rem #ccc;
    padding-top: 20px;
    padding-left: 40px;
    padding-bottom: 10px;
  }
.box-style3{
    box-shadow: 0 0 5px 5px red;
    padding-left: 30px;
    padding-top: 30px;
    
}
.box-style4{
  box-shadow: 0.5rem 0.5rem black, -0.5rem -0.5rem #ccc;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.box-style5{
    box-shadow: 0 0 5px 5px red;
    padding-top: 20px;
    padding-left: 40px;
    padding-bottom: 10px;
}
.box-style6{
    box-shadow: 0 -5px 3px -3px black, 0 5px 3px -3px black;
    padding-left: 20px;
}

.box-gapping{
  gap: 110px;
    
}

/* .hoverBox:hover{
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
      transition: 0.3s;
  } */
  .hoverBox {
    --h: 1.2em;   /* the height */
    
    line-height: var(--h);
    color: #0000;
    text-shadow: 
      0 var(--_i,var(--h)) #fff,
      0 0 rgb(0 0 0 / var(--_i,1));
    background: 
      linear-gradient(#1095c1 0 0) 
      bottom/100% calc(100% - var(--_i,1)*100%) no-repeat;
    overflow: hidden;
    transition: 0.3s;
  }
  .hoverBox:hover {
    --_i: 0;
    box-shadow: none;
  }
  
  .hoverBox a{
    text-decoration: none;
    font-weight: 600;
    font-size: 25px;
    color: rgb(73, 73, 206);
  }
.cards-body{
    margin-left: 7px;
}
.cards-body:hover{
    font-style: normal;
    background-color: indigo;
    padding: 10px;
}
  @media only screen and (max-width:688px) {
    .bio-title{
        
        grid-template-columns: repeat(1, 1fr);
        
    }
    .edu-exp{
      
        grid-template-columns: repeat(1, 1fr);
    }
    .bio-style{
      
        grid-template-columns: repeat(1, 1fr);
    }
    .aboutbanner{
        height: 1400px;
    }

}

@media only screen and (min-width:689px) and (max-width: 991px){
    .bio-title{
       
        grid-template-columns: repeat(1, 1fr);
    }
    .edu-exp{
        
        grid-template-columns: repeat(1, 1fr);
    }
}
/*============= massenger plugin style ========== */


.maseenger-style MessengerChat{
    margin-right: 15px;
    width: 55px;
    height: 50px;
    text-align: center;
    font-size: 22px;
    z-index: 100;
}
.maseenger-style MessengerChat:hover{
   color: orange;
}

@media  screen and (max-width: 767px) {
    .maseenger-style MessengerChat{
        margin-left: 50px;
    }
    .maseenger-style MessengerChat{
        height: 30px;
        width: 30px;
    }
}