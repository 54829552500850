.number {
    color: indigo;
    font-weight: bold;
  }

  .number .counter {
    color: indianred;
    font-weight: bold;
    font-style: italic;
    font-size: 1.9em;
    margin: 6px 0;
  }
  .number span {
    font-size: 1.2em;
    display: block;
  }
  .numbers {
    display: flex;
    justify-content: space-around;
    margin-top: 60px;
  }
 .plusSign{
  position: relative;
  left: 22px;
  bottom: 47px;
  font-size:x-large;

 }
.titles{
  position: relative;
  bottom: 30px;
}