.footerStyle{
    margin-top: 30px;
    background-color: #151320;
    color: white;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.footerStyle h4{
 text-decoration: underline;
}

.btn-direction{
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #ED2025;
    border-radius: 2px;
    padding: 6px;
    padding-left: 42px;
    padding-right: 42px;
    background:red;
    color:white;
}
.support-style {
    font-size:17px;
    text-decoration: none;
    color:white;
    
}
.fabicons a{
  text-decoration: none;
  font-size: 30px;
  color: tomato;
}

.footerLast{
    padding: 18px;
    background-color: #0e0d16;
    color: whitesmoke;
    letter-spacing: 3px;
}

.fabicons .fa-facebook:hover {
  color: blueviolet;
}
.fabicons .fa-instagram:hover {
  color: rgb(209, 159, 159);
}
.fabicons .fa-twitter:hover {
  color: blueviolet;
}
.fabicons .fa-youtube:hover {
  color: red;
}

@media only screen and (max-width:688px) {
        .footerStyle{
            grid-template-columns: repeat(1, 1fr);
        }
        .firstcol{
            margin-bottom: 15px;
        }

    }

@media only screen and (min-width:689px) and (max-width: 991px){
        .footerStyle{
            grid-template-columns: repeat(2, 1fr);
        }
        .firstcol{
            margin-bottom: 15px;
        }
    }
